    <clr-modal [(clrModalOpen)]="!success || !failure">
        <h3 class="modal-title">Action Status</h3>
        <div class="modal-body">
            <div class="clr-row">
                <div class="clr-col">
                        <clr-alert [clrAlertType]="'success'" [clrAlertClosable]="false" [(clrAlertClosed)]='success' >
                                <clr-alert-item>
                                    <span class="alert-text">
                                        Email sent successfully!
                                    </span>
                                </clr-alert-item>
                            </clr-alert>
                            <clr-alert [clrAlertType]="'danger'" [(clrAlertClosed)]='failure'>
                                    <clr-alert-item>
                                        <span class="alert-text">
                                            An error occured! Check internet connection or code.
                                        </span>
                                    </clr-alert-item>
                                </clr-alert>
                </div>
            </div>                
        </div>
        <div class="modal-footer">
            <button class="btn btn-primary" type="button" *ngIf="!success" (click)="login()">Login</button>
        </div>
        </clr-modal>

        <clr-main-container>
            
            <div class="content-container">
                <div class="content-area">
                    
                    <div class="clr-row clr-justify-content-center" style="min-height:100%;">
                        <div class="clr-col-lg-4 clr-col-md-6 clr-col-sm-7 clr-align-self-center">
                            <div class="clr-col" style="text-align:center;">
                                <img src="../../../assets/bays_logo.png" class="logo">
                            </div>
                        
        
                            <div class="card">
                                <div class="card-block">
                                    <div class="clr-col" style="text-align:start;width:100%;">

                                        <div class="clr-row clr-justify-content-center">

                                            
                                            <form clrForm [formGroup]="recoverForm" (ngSubmit)="sendEmail()">

                                                <div class="clr-row clr-justify-content-center">
                                                    <div class="clr-col" style="width:100%;" style="text-align:center;">
                                                        
                                                        <h2 style="font-weight:500;color:rgb(53, 51, 55);margin:0;padding:0;">Resend Verification Email</h2>

                                                        <p>Enter your registered email below and press send to resend the verification email.</p>

                                                        <clr-alert *ngIf="invalidEmail" [clrAlertSizeSmall]="true" [clrAlertType]="'danger'" (clrAlertClosedChange)="invalidEmail=false">
                                                            <clr-alert-item>
                                                                <span class="alert-text">
                                                                    Invalid email.
                                                                </span>
                                                            </clr-alert-item>
                                                        </clr-alert>

                                    

                                                        <clr-input-container style="text-align:left;">
                                                            <input clrInput type="text" placeholder="Email" formControlName="email" size="30" />
                                                            <clr-control-error *clrIfError="'required'">This field is required!</clr-control-error>
                                                        </clr-input-container>


                                                        <br>

                                                            
                                                <button class="btn btn-primary recover-button" *ngIf="!loading" type="submit">Submit</button>
                                                <span class="spinner spinner-inline" *ngIf="loading">
                                                    Loading...
                                                </span>
            

                                                    </div>
                                                </div>

                                                
                                            
            
                                            
            
                                            </form>

                                        
                                                    
            
                                            
                                                

                                    
                                            
                                                
                                            

                                            

                                        </div>
        
                                            
            
                                        
            
                                        
            
                                            
                                    </div>
                                    </div>
                                </div>
                            </div>  

                    </div>
                </div>
            </div>
        </clr-main-container>
        