import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { Store, Actions, ofActionSuccessful, ofActionErrored, ofActionDispatched } from '@ngxs/store';
import { Signin } from 'src/app/store/authentication/authentication-actions';
import { takeUntil } from 'rxjs/operators';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { GetAdmin, GetUser } from 'src/app/store/user/user-actions';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy{

  invalidLogin:boolean;
  loginForm: FormGroup;
  feedback: {username:string, password:string};
  destroy$= new Subject<void>();
  loading: boolean;

  
  
  ngOnDestroy():void {
    this.destroy$.next();
    this.destroy$.complete();
  }
  
  constructor(private formBuilder:FormBuilder, private router:Router, private store: Store, private actions: Actions) { }

  ngOnInit(): void {
    this.createForm();
   //this.isLoggedIn();
    this.actions.pipe(ofActionDispatched(Signin), takeUntil(this.destroy$)).subscribe(()=>{this.loading=true;})
    this.actions.pipe(ofActionSuccessful(GetUser),takeUntil(this.destroy$)).subscribe(()=> {this.loading=false;this.router.navigate(['/home'])});
    this.actions.pipe(ofActionErrored(Signin),takeUntil(this.destroy$)).subscribe(()=> {this.invalidLogin=true;this.loading=false;});
  }



//Create Login Form.
  createForm():void{
    this.loginForm=this.formBuilder.group({
        email:['',Validators.required],
        password:['',Validators.required]
      });
  }

//Erase all contents of form.
  resetForm():void{
    this.loginForm.reset();
  }



  submit(){
    if(this.loginForm.valid){

      this.feedback=this.loginForm.value;

      this.store.dispatch(new Signin(this.feedback));

    }
    else{
      this.invalidLogin=true;
    }

  }

  signup(){
    this.router.navigate(['/authentication/sign-up']);
  }

  forgotPassword(){
    this.router.navigate(['/authentication/forgot-password']);
  }
  

  isLoggedIn(){

    const authentication = localStorage.getItem('authentication');

    if(authentication!='undefined')
    var parsed=JSON.parse(authentication);

    if(parsed!={}){
      this.router.navigate(['/home']);
    }
  }
}
