<clr-main-container style="padding:0;margin:0;">
    <app-header></app-header>
    <div class="content-container" style="padding:0;margin:0;">
        <div class="content-area" style="padding:0;margin:0;">
            <div class="clr-row banner" style="padding:2%;min-height:60%;background-size: cover;background-repeat: no-repeat;">
                <div class="clr-col-12 clr-align-self-center">
                    <h1 style="font-size:40px;color:white;font-weight: bolder;">Need A Loan? Get It The <b style="font-weight: bolder;">GREEN</b> Way.</h1><br>

        
                    
                </div>

                

             


                
            </div>

            <div class="clr-row clr-justify-content-center" style="color:#205C28;background-color: #eff1f0;border-bottom:2px solid rgba(0,0,0,0);min-height: 35%;padding:2%;">
                <div class="clr-col-lg-4 feature" ><h6 style="color:#205C28;font-weight: bolder;font-size:17px;">Fast, Free and EZEE Loan Simulation Process</h6><p style="color:#205C28;">Check your eligibility in just a few minutes and click</p></div>
                <div class="clr-col-lg-4 feature"><h6 style="color:#205C28;font-weight: bolder;font-size:17px;">Increase Your Chances of Acceptance</h6><p style="color:#205C28;">Know your likelihood of getting a loan before even setting foot in a bank</p></div>
                <div class="clr-col-lg-4" ><h6 style="color:#205C28;font-weight: bolder;font-size:17px;">Save Time and Energy</h6><p style="color:#205C28;">Get loan information from the comfort of your home. Stay green by travelling less.</p></div>
            </div>
            <div class="clr-row clr-justify-content-center" style="padding:1%;">
                <div class="clr-col-lg-7 ">
                    <br>
                    <h3>How does Ezee Loans help?</h3>
                    <p style="font-size:17px;">
                      
                        Not sure whether you’re ready to commit to a loan? With our loan calculator, you can find out what your repayments might look like each month, and how much interest you could end up paying.

Start by selecting what the personal loan is for, then tell us how much you want to borrow, and how long you’ll need to pay the money back.

From there, you can enter your information to check if you are eligible for the loan. If you choose to check your eligibility for a loan we will to assess your financial circumstances and contact you within 24 hours. This may change any offered interest rate and size of the monthly repayments. Our loans are available to Guyanese residents aged 18 and over. Rates will vary depending on loan amount and individual circumstances. 


                    </p>
                    <div class="card" style="margin-bottom:20px;">
                        <div class="card-header" style="opacity:0.7;">
                         <span style="font-weight: bolder;font-size:20px;">Loan Eligibility Checker</span>
                       
                        </div>
                        <div class="card-block" style="text-align: center;">

                 
                              <h4>Select Your Preferred Loan Type</h4>

                              <span class="spinner"  style="margin:3%" *ngIf="!pageLoaded">
                                Loading...
                              </span>
                              

                              <div *ngIf="pageLoaded">
                                <div  *ngFor="let type of loanTypes" style="padding:10%;">
                                    <button class="btn btn-success" style="color:#EDF9F1; border-radius:20px;" (click)="calculateLoan(type)">
                                        {{type.name}}
                                    </button>
                                    <br>
                                </div>
                              </div>
                          
                          

                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>

    <clr-modal  [(clrModalOpen)]="calculate" [clrModalClosable]="true" [clrModalSize]="'md'">
        <h3 class="modal-title">Calculate Your Installment</h3>
        <div class="modal-body" >
            <div class="clr-row">
                <div class="clr-col">
                    <h5 style="margin:0;">{{loanType?.name}}</h5>
                    {{loanType?.description}}
                    <form clrForm [formGroup]="form">

                       
                        <clr-input-container>
                            <label>Loan Amount</label>
                            <input clrInput type="number" formControlName="principal" (keyup.enter)="calculateInstallmentAndTotal()"/>
                        </clr-input-container>
                            
                        <clr-input-container>
                            <label>Annual Interest Rate</label>
                            <input clrInput formControlName="interestRate" type="number" (keyup.enter)="calculateInstallmentAndTotal()"/>
                          </clr-input-container>
                       
                          <clr-input-container>
                            <label>Number of Years</label>
                            <input clrInput type="number"  formControlName="numberOfYears" (keyup.enter)="calculateInstallmentAndTotal()"/>
                          </clr-input-container>
                        
                          <clr-input-container>
                            <label>Installment</label>
                            <input clrInput [readonly]="true" type="number" value="{{installment}}"/>
                          </clr-input-container>
                         
                          <clr-input-container>
                            <label>Total Amount</label>
                            <input clrInput [readonly]="true" type="number" value="{{totalAmount}}"/>
                          </clr-input-container>
                     
                      </form>
                      <b>NB: You must sign up to check your eligibility!</b>
                     
                </div>
                <div class="clr-break-row"></div>
                
            </div>        
            <div class="clr-row clr-justify-content-start">

                <div class="clr-col">
                    <button class="btn btn-success" style="color:#EDF9F1; width:100%;" (click)="calculateInstallmentAndTotal()">Calculate</button>
                 
                </div>

                <div class="clr-col">
                    <button class="btn btn-success" [disabled]="!this.form.valid" style="color:#EDF9F1; width:100%;" (click)="checkEligible()">Check Eligibility</button>
                </div>
                
           

            </div>
        
        </div>
     

       
               

    

    </clr-modal>

</clr-main-container>
