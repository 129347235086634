export class GetUser{
    constructor(public id: number){
    
    }
    
    static readonly type="[User] GetUser";
    
    }
    
    
    export class ApproveUser{
        constructor(public id: number){

        }
        static readonly type="[Admin] Approve User";
    }

    export class GetAllUser{
        constructor(){
        
        }
        
        static readonly type="[User] GetAllUser";
        
        }
    
    export class UpdateUser{
        constructor(public update: any, public id: number){
    
        }
    
        static readonly type="[User] UpdateUser";
    }
        
    export class CreateUser{
        constructor(public create:any){
    
        }
    
        static readonly type="[User] CreateUser";
    }

    export class GetAdmin{
        constructor(){

        }

        static readonly type="[Admin] GetAdmin";
    }

