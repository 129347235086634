export class GetRole{
    constructor(public id: number){
    
    }
    
    static readonly type="[Role] GetRole";
    
    }
    
    export class GetAllRole{
        constructor(){
        
        }
        
        static readonly type="[Role] GetAllRole";
        
        }
    
    export class UpdateRole{
        constructor(public update: any, public id: number){
    
        }
    
        static readonly type="[Role] UpdateRole";
    }
        
    export class CreateRole{
        constructor(public create:any){
    
        }
    
        static readonly type="[Role] CreateRole";
    }


