import { State, Selector, Action, StateContext } from '@ngxs/store';
import { Signin, Signout, VerifyCode, ResetPassword, CheckEmail, ResendVerify, CheckNumber } from './authentication-actions';
import { tap } from 'rxjs/operators';
import jwt_decode from 'jwt-decode';
import { Injectable } from '@angular/core';
import { AuthenticationStateModel } from './authentication-state-model';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { GetAdmin, GetUser } from '../user/user-actions';

@State<AuthenticationStateModel>({
    name: 'authentication' 
  })
  @Injectable()
  export class AuthenticationState {

    @Selector()
    static token(state: AuthenticationStateModel) {
      return state.token;
    }
  
  
    @Selector()
    static id(state: AuthenticationStateModel) {
      return state.id;
    }
  
    @Selector()
    static authorization(state: AuthenticationStateModel) {
      return state.authorization;
    }

    constructor(private service: AuthenticationService) {

    }

    
  @Action(Signin, { cancelUncompleted: true })
  login(
    { patchState, dispatch, getState}: StateContext<AuthenticationStateModel>,
    {payload}: Signin
  ) {
    return this.service.signin(payload).pipe(
      tap(result => {

        if(result){
        let decoded=jwt_decode(result.token);

        patchState({
          token: result.token, 
          id:parseInt(decoded['nameid'],10),
          authorization: decoded['role']
        });

        dispatch(new GetUser(parseInt(decoded['nameid'],10)));

      }
      })
    );
  }

  

  @Action(Signout, { cancelUncompleted: true })
  logout({ setState, getState,dispatch }: StateContext<AuthenticationStateModel>) {
    return this.service.signout().pipe(
      tap(()=> {
        
      })


    );


  }

  @Action(VerifyCode,  { cancelUncompleted: true })
  verifyCode({patchState}:StateContext<AuthenticationStateModel>,{payload}:VerifyCode){
    return this.service.verifyCode(payload).pipe(tap(
      result => {

      }));
  }

  @Action(CheckEmail,  { cancelUncompleted: true })
  checkEmail({patchState}:StateContext<AuthenticationStateModel>,{email}:CheckEmail){
    return this.service.checkEmail(email).pipe(tap(
      result => {

      }));
  }

  @Action(CheckNumber,  { cancelUncompleted: true })
  checkUsername({patchState}:StateContext<AuthenticationStateModel>,{number}:CheckNumber){
    return this.service.checkNumber(number).pipe(tap(
      result => {

      }));
  }

  @Action(ResetPassword, { cancelUncompleted: true })
ResetPassword({dispatch}:StateContext<AuthenticationStateModel>,{email}:ResetPassword){
  return this.service.changePassword(email).pipe(
    tap(
    result =>{
      
    }
  ))
}

@Action(ResendVerify, { cancelUncompleted: true })
resend({dispatch}:StateContext<AuthenticationStateModel>,{email}:ResendVerify){
  return this.service.resendVerify(email).pipe(
    tap(
    result =>{
      
    }
  ))
}

  
  }