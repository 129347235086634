import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { SignupComponent } from './signup/signup.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { AuthenticationComponent } from './authentication/authentication.component';
import { ResendVerifyComponent } from './resend-verify/resend-verify.component';
import { VerifiedComponent } from './verified/verified.component';



const routes: Routes = [
  {
    path:'authentication',
    component: AuthenticationComponent,
    children:[ 
      {
      path:'login',
      component:LoginComponent
    },
    {
      path:'sign-up',
      component:SignupComponent
    },
    {
      path:'forgot-password',
      component:ForgotPasswordComponent
    },
    {
      path:'resend-verify',
      component: ResendVerifyComponent
    },
    {
      path:'verify',
      component: VerifiedComponent
    },
    {
      path:'**',
      redirectTo:'login',
      pathMatch:'full'
    }

    ]
  }
 
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AuthenticationRoutingModule { }
