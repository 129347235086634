import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { SplashScreenComponent } from './splash-screen/splash-screen.component';
import { ClarityModule } from '@clr/angular';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './shared/shared.module';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsModule, NGXS_PLUGINS } from '@ngxs/store';
import { environment } from 'src/environments/environment';
import { ErrorInterceptor } from './interceptors/error.interceptor';
import { TokenInterceptor } from './interceptors/token.interceptor';
import { appState } from './store';
import { logoutPlugin } from './store/logout-reducer';
import { AppRoutingModule } from './app-routing.module';
import { HomeComponent } from './home/home.component';
import { DSRCalculatorComponent } from './dsr-calculator/dsr-calculator.component';
import { AuthenticationModule } from './authentication/authentication.module';

@NgModule({
  declarations: [
    AppComponent,
    SplashScreenComponent,
    HomeComponent,
    DSRCalculatorComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    BrowserModule,
    AuthenticationModule,
    AppRoutingModule,
    ClarityModule,
    SharedModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule,
    NgxsModule.forRoot(appState, {
      developmentMode: environment.production
    }),
    NgxsLoggerPluginModule.forRoot({
      disabled: environment.production
    }),
    NgxsStoragePluginModule.forRoot({key:['authentication','User']}),
  ],
  providers: [
    {
      provide:HTTP_INTERCEPTORS,
      useClass:TokenInterceptor,
      multi:true
    },
  { provide: HTTP_INTERCEPTORS, 
useClass: ErrorInterceptor,
      multi: true },
      {
        provide: NGXS_PLUGINS,
        useValue: logoutPlugin,
        multi: true
      }  
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
