import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { VerifyCode, ResetPassword } from 'src/app/store/authentication/authentication-actions';
import { Actions, Store, ofActionSuccessful, ofActionDispatched, ofActionErrored } from '@ngxs/store';
import { takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  destroy$= new Subject<void>();

  success:boolean=true;
  failure:boolean=true;

  loading: boolean=false;
  statusModal: boolean=false;

  confirmationModal: boolean=false;
  confirmationMessage: string;

  step:number=1;
  recoverForm: FormGroup;
  codeForm: FormGroup;
  invalidEmail: boolean;
  invalidCode: boolean;
  
  ngOnDestroy():void {
    this.destroy$.next();
    this.destroy$.complete();
  }
  
  createForm(){
    this.recoverForm=this.formBuilder.group({
      email:['', [Validators.required,Validators.email]]
    });

    this.codeForm=this.formBuilder.group({
      code:['', Validators.required],
      password:['', Validators.required],
      confirmPassword:['', Validators.required]
    },{
      validator:[
      this.mustMatch('password', 'confirmPassword'),
       this.measureStrength('password')]
    }
);
  }

  goBack(){
    this.step=1;
  }


  changePassword(){
    if(this.codeForm.valid)
    this.store.dispatch(new VerifyCode({email: this.recoverForm.value.email,password: this.codeForm.value.password, code: this.codeForm.value.code}));
  }

  sendEmail(){
    if(this.recoverForm.valid)
    this.store.dispatch(new ResetPassword(this.recoverForm.value.email));
  }

  constructor(private formBuilder: FormBuilder, private actions: Actions, private store:Store, private router: Router) { }

  ngOnInit(): void {

    this.actions.pipe(ofActionSuccessful(ResetPassword),takeUntil(this.destroy$)).subscribe(()=> {this.step=2;this.loading=false;});
    this.actions.pipe(ofActionDispatched(ResetPassword),takeUntil(this.destroy$)).subscribe(()=> {this.loading=true;this.success=true;this.failure=true;});
    this.actions.pipe(ofActionErrored(ResetPassword),takeUntil(this.destroy$)).subscribe(()=> {this.invalidEmail=true;this.loading=false;});

    this.actions.pipe(ofActionSuccessful(VerifyCode),takeUntil(this.destroy$)).subscribe(()=> {this.success=false;this.loading=false;});
    this.actions.pipe(ofActionDispatched(VerifyCode),takeUntil(this.destroy$)).subscribe(()=> {this.loading=true;this.success=true;this.failure=true;});
    this.actions.pipe(ofActionErrored(VerifyCode),takeUntil(this.destroy$)).subscribe(()=> {this.failure=false;this.loading=false;});

    this.createForm();
  }

  mustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
        const control = formGroup.controls[controlName];
        const matchingControl = formGroup.controls[matchingControlName];

        if (matchingControl.errors && !matchingControl.errors.mustMatch) {
            // return if another validator has already found an error on the matchingControl
            return;
        }

        // set error on matchingControl if validation fails
        if (control.value !== matchingControl.value) {
            matchingControl.setErrors({ mustMatch: true });
        } else {
            matchingControl.setErrors(null);
        }
    }
}

login(){
  this.router.navigate(['/authentication/login']);
}


measureStrength(controlName: string) {  


  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];

    let pass= control.value;

    if (control.errors && !control.errors.weakPassword) {
        // return if another validator has already found an error on the matchingControl
        return;
    }

    let score = 0;  

    // award every unique letter until 5 repetitions  
  
    let letters = {};  
  
    for (let i = 0; i< pass.length; i++) {  
  
    letters[pass[i]] = (letters[pass[i]] || 0) + 1;  
  
    score += 5.0 / letters[pass[i]];  
  
    }  
  
    // bonus points for mixing it up  
  
    let variations = {  
  
    digits: /\d/.test(pass),  
  
    lower: /[a-z]/.test(pass),  
  
    upper: /[A-Z]/.test(pass),  
  
    nonWords: /\W/.test(pass),  
  
    };  
  
  
  
    let variationCount = 0;  
  
    for (let check in variations) {  
  
    variationCount += (variations[check]) ? 1 : 0;  
  
    }  
  
    score += (variationCount - 1) * 10;  
  
    score= Math.trunc(score); 

    // set error on matchingControl if validation fails
    if (score < 40) {
       control.setErrors({ weakPassword: true });
    } else {
       control.setErrors(null);
    }
}
}  
}
