<clr-main-container>
    <div class="content-container">
        <div class="content-area">
            <div class="clr-row clr-justify-content-center" style="min-height:100%;">
                <div class="clr-col-lg-4 clr-col-md-6 clr-col-sm-7 clr-align-self-center">
                    <div class="clr-col" style="text-align:center;">
                   
                    </div>
                   

                    <div class="card">
                        <div class="card-block">
                            <div class="clr-col" style="text-align:center;">

                                <div class="clr-row clr-justify-content-center">

                                    <img src="../../../assets/logo.png" class="logo">
                                    <div class="clr-break-row"></div>

                                    
                               
                   
                                    
                                        <form clrForm clrLayout="vertical" [formGroup]="loginForm">
                                            <div class="clr-row clr-justify-content-center">
                                                <div class="clr-col">

                                                    <h3 style='font-weight:bolder;opacity:0.75;'>Sign In To Your Account </h3>
                                                    
                                                    <clr-alert *ngIf="invalidLogin" [clrAlertSizeSmall]="true" [clrAlertType]="'danger'" (clrAlertClosedChange)="invalidLogin=false">
                                                        <clr-alert-item>
                                                            <span class="alert-text">
                                                                Invalid email or password.
                                                            </span>
                                                        </clr-alert-item>
                                                    </clr-alert>

                                                    <clr-input-container style="margin-bottom:15%;margin-top:15%;">
                                                        <input clrInput name="email" placeholder="Email or Contact Number" size="35" formControlName="email" (keyup.enter)="submit()"  />
                                                      </clr-input-container>
                                                      <clr-password-container >
                                                         
                                                          <input clrPassword name="password"  placeholder="Password" size="30" formControlName="password" (keyup.enter)="submit()" />
                                                      </clr-password-container>

                                                     
                                                </div>
                                            </div>
                                            
                                          </form>

                                          <div class="clr-break-row"></div>
                                          <button class="btn btn-primary login-button" *ngIf="!loading" [disabled]="!loginForm.valid" (click)="submit()">LOGIN</button>

                                          <span class="spinner spinner-inline" *ngIf="loading">
                                            Loading...
                                        </span>

                                          <div class="clr-break-row"></div>
                                          <button class="btn btn-link option-button" (click)="forgotPassword()">FORGOT YOUR PASSWORD?</button>
                                          <div class="clr-break-row"></div>
                                          <button class="btn btn-link option-button" (click)="signup()">NEW USER? SIGN UP!</button>
                                </div>

                            </div>
                            </div>
                        </div>
                    </div>  
            </div>
        </div>
    </div>
</clr-main-container>
