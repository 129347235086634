export class Signin {
    static readonly type = '[Authentication] Signin';
  
    constructor(public payload:{username:string, password:string}) {
  
    }
  }
  
  export class Signout {
    static readonly type = '[Authentication] Signout';
  
    constructor(){
      
    }
  }
  

  export class ResetPassword{

    static readonly type='[Authentication] ResetPassword';

    constructor(public email: string){

    }

  }

  export class VerifyCode{

    static readonly type='[Authentication] VerifyPassword';

    constructor(public payload: any){
      
    }

  }

  export class CheckEmail{
    static readonly type='[Authentication] CheckEmail';

    constructor(public email: string){

    }
  }

  export class CheckNumber{
    static readonly type='[Authentication] CheckContactNumber';

    constructor(public number: string){
      
    }


  }

  export class ResendVerify{
    static readonly type="[Authentication] Resend";
    constructor (public email:string){}
  }