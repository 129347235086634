import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Store } from '@ngxs/store';
import { throwError, Observable, of, fromEvent } from 'rxjs';
import { retry, catchError, mapTo, retryWhen, switchMap } from 'rxjs/operators';
import { api } from './api-link';


@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {


  constructor(private http: HttpClient,private _store:Store) {
  }

  httpOptions = {
     headers: new HttpHeaders({
       'Content-Type': 'application/json'
     })
   }

   errorHandl(error) {
   let errorMessage = '';
   if(error.error instanceof ErrorEvent) {
     // Get client-side error
     errorMessage = error.error.message;
   } else {
     // Get server-side error
     errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
   }
   console.log(errorMessage);
   return throwError(errorMessage);
}

private onlineChanges$ = fromEvent(window, 'online').pipe(mapTo(true));
  
get isOnline() {
  return navigator.onLine;
}

signin(credentials):Observable<any> {
  return this.http.post(api+'Login/Authenticate',
    JSON.stringify(credentials),this.httpOptions).pipe(
      retryWhen(errors => {
        if (this.isOnline) {
          return errors.pipe(switchMap(err => throwError(err)));
        }

        return this.onlineChanges$;
      }),
      catchError(this.errorHandl)
    );

}

checkEmail(email: string) {
  return this.http.post(api+'Login/Email',
  JSON.stringify(email),this.httpOptions).pipe(
    retry(1),
    catchError(this.errorHandl)
  );
}

resendVerify(email: string) {
  return this.http.post(api+'Login/ResendVerifyEmail',
  JSON.stringify(email),this.httpOptions).pipe(
    retry(1),
    catchError(this.errorHandl)
  );
}


checkNumber(number: string) {
  return this.http.post(api+'Login/ContactNumber',
  JSON.stringify(number),this.httpOptions).pipe(
    retry(1),
    catchError(this.errorHandl)
  );
}

changePassword(email):Observable<any>{
  return this.http.post(api+'Login/ResetPassword',
  JSON.stringify({email:email}),this.httpOptions).pipe(
    retry(1),
    catchError(this.errorHandl)
  );


}


verifyCode(payload):Observable<any>{

  return this.http.post(api+'Login/VerifyCode',
  JSON.stringify(payload),this.httpOptions).pipe(
    retry(1),
    catchError(this.errorHandl)
  );

}


signout(): Observable<null> {
    return of(null);
}
}