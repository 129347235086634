<div
class="app-splash-screen"
[ngStyle]="{ left: windowWidth }"
*ngIf="showSplash"
>
<div class="app-splash-inner">
  <div class="app-logo animate__animated animate__bounce"></div>
  <br>
  <div class="progress loop">
    <progress></progress>
  </div>
  
</div>
</div>