import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { Actions, Store, ofActionSuccessful, ofActionDispatched, ofActionErrored } from '@ngxs/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ResendVerify } from 'src/app/store/authentication/authentication-actions';

@Component({
  selector: 'app-resend-verify',
  templateUrl: './resend-verify.component.html',
  styleUrls: ['./resend-verify.component.scss']
})
export class ResendVerifyComponent implements OnInit {

  destroy$= new Subject<void>();

  success:boolean=true;
  failure:boolean=true;

  loading: boolean=false;
  statusModal: boolean=false;

  confirmationModal: boolean=false;
  confirmationMessage: string;

  recoverForm: FormGroup;
  invalidEmail: boolean=false;


  
  ngOnDestroy():void {
    this.destroy$.next();
    this.destroy$.complete();
  }
  
  createForm(){
    this.recoverForm=this.formBuilder.group({
      email:['', [Validators.required,Validators.email]]
    });

  }



  sendEmail(){
    if(this.recoverForm.valid)
    this.store.dispatch(new ResendVerify(this.recoverForm.value.email));
  }

  constructor(private formBuilder: FormBuilder, private actions: Actions, private store:Store, private router: Router) { }

  ngOnInit(): void {

  
    this.actions.pipe(ofActionSuccessful(ResendVerify),takeUntil(this.destroy$)).subscribe(()=> {this.success=false;this.loading=false;});
    this.actions.pipe(ofActionDispatched(ResendVerify),takeUntil(this.destroy$)).subscribe(()=> {this.loading=true;this.success=true;this.failure=true;});
    this.actions.pipe(ofActionErrored(ResendVerify),takeUntil(this.destroy$)).subscribe(()=> {this.failure=false;this.loading=false;this.invalidEmail=true;});

    this.createForm();
  }


login(){
  this.router.navigate(['/authentication/login']);
}


}
