import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Select, Store, Actions, ofActionSuccessful, ofActionErrored, ofActionDispatched } from '@ngxs/store';
import * as moment from 'moment';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Loan } from '../models/loan';
import { AuthenticationState } from '../store/authentication/authentication-state';
import { CreateLoan, UpdateLoan } from '../store/loan/state/loan.actions';
import { LoanState } from '../store/loan/state/loan.state';

@Component({
  selector: 'app-dsr-calculator',
  templateUrl: './dsr-calculator.component.html',
  styleUrls: ['./dsr-calculator.component.scss']
})
export class DSRCalculatorComponent implements OnInit {

  
        @Select(AuthenticationState.id) id$: Observable<number>;
        id:number;

        @Select(AuthenticationState.authorization) role$: Observable<number>;
        role:string;

        @Select(LoanState.loans) loans$:Observable<Loan[]>;
        loans: Loan[];


          pageLoaded: boolean = true ;
    personalDataForm: FormGroup;
    financeForm: FormGroup;
    step: number = 1;
  contactInfoForm: FormGroup;
  selectedFile: string = null;

  move(step:number){
    if(step==2){
     if(this.personalDataForm.valid)
     this.step=step;
    }
    else if(step==3){
      if(this.contactInfoForm.valid)
      this.step=step;
    }
    else if(step==1){
     this.step=step;
    }

   
  }

    

        constructor(private store: Store, private actions: Actions, private formBuilder:FormBuilder, private router:Router, private activatedRoute:ActivatedRoute) {
        this.loans$.subscribe(loans => this.loans= loans);

    }

        destroy$= new Subject<void>();

        ngOnDestroy():void {
            this.destroy$.next();
            this.destroy$.complete();
        }

        success:boolean=true;
        failure:boolean=true;

        
        loading: boolean=false;
        statusModal: boolean=false;

    ngOnInit(): void {

      console.log(history.state.data)

      if(!history.state.data){
        this.router.navigate(['/home']);
      }

      this.createForm();
      
        this.actions.pipe(ofActionSuccessful(CreateLoan),takeUntil(this.destroy$)).subscribe(()=> {this.success=false;this.loading=false;this.statusModal=true;this.confirmationModal=false;});
        this.actions.pipe(ofActionErrored(CreateLoan),takeUntil(this.destroy$)).subscribe(()=> {this.failure=false;this.loading=false;this.statusModal=true;this.confirmationModal=false;});
        this.actions.pipe(ofActionDispatched(CreateLoan),takeUntil(this.destroy$)).subscribe(()=> {this.loading=true;this.success=true;this.failure=true;});

        
    }

    form: FormGroup;

    onFileChange(event) {
      const reader = new FileReader();
      
      if(event.target.files && event.target.files.length) {
        const [file] = event.target.files;
        reader.readAsDataURL(file);
      
        reader.onload = () => {
     
       
          this.selectedFile= reader.result.toString().substring(reader.result.toString().lastIndexOf(',') + 1)
        
     
        };
     
      }else{
        this.selectedFile=null;
      }
    }


    createForm(){
      this.personalDataForm=this.formBuilder.group({
        firstName:['', Validators.required],
        middleName:[''],
        lastName:['', Validators.required],
        dob:['', [Validators.required]],
       
      }
      );

      this.contactInfoForm=this.formBuilder.group({
        contactNumber:['', Validators.required],
        otherNumber: [""],
        address: ["",Validators.required],
        email:["", Validators.required]
      })
  
      this.financeForm=this.formBuilder.group({
        employmentYears: [null,Validators.required],
        gml:[null,Validators.required],
        gms: [null,Validators.required],
        dsr:[null]
        }, 
        );
    }

    dsr:number;

    calculateDSR(){
      this.dsr = ((history.state.data.installmentAmount+this.financeForm.value.gml)/this.financeForm.value.gms);
    }

    goToHome(){
      this.router.navigate(['/home']);
    }
    



    confirmationModal: boolean=false;
    confirmationMessage: string;
    objectId: number;
    pendingAction: string;


    confirm(action: string, objectId: number){

        this.objectId=objectId;
        this.pendingAction=action;

        
        if(action=="create" && this.financeForm.valid){
        this.confirmationMessage="Are you sure you want to send this to the bank?";
        this.confirmationModal=true;
        }

        
        }
    

    performAction(){
        if(this.pendingAction=="create")
        this.store.dispatch(new CreateLoan({
          loanTypeId: history.state.data.loanTypeId,
          loanAmount: history.state.data.loanAmount,
          installmentAmount: history.state.data.installmentAmount,
          dob: this.personalDataForm.value.dob,
          cost: history.state.data.cost,
          email: this.contactInfoForm.value.email ,
          firstName: this.personalDataForm.value.firstName,
          lastName: this.personalDataForm.value.lastName,
          contactNumber: this.contactInfoForm.value.contactNumber,
          otherNumber: this.contactInfoForm.value.otherNumber,
          address: this.contactInfoForm.value.address,
          employmentYears: this.financeForm.value.employmentYears,
          gml: this.financeForm.value.gml + history.state.data.installmentAmount,
          gms: this.financeForm.value.gms,
          path: this.selectedFile
        }));


    }




}
