import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DSRCalculatorComponent } from './dsr-calculator/dsr-calculator.component';
import { AuthenticationGuard } from './guards/authentication-guard.guard';
import { HomeComponent } from './home/home.component';


const routes: Routes = [
    {
        path:'home',
        component:HomeComponent
    },
    {
      path:'dsr',
      component:DSRCalculatorComponent,
      canActivate: [AuthenticationGuard]
    },
  {
    path:'**',
    redirectTo:'home',
    pathMatch:'full'
  }
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
