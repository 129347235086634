import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Select, Store, Actions, ofActionSuccessful, ofActionErrored, ofActionDispatched } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LoanType } from '../models/loan-type';
import { User } from '../models/user';
import { Signout } from '../store/authentication/authentication-actions';
import { AuthenticationState } from '../store/authentication/authentication-state';
import { CreateLoanType, GetAllLoanType, UpdateLoanType } from '../store/loantype/state/loantype.actions';
import { LoanTypeState } from '../store/loantype/state/loantype.state';
import { GetUser } from '../store/user/user-actions';
import { UserState } from '../store/user/user-state';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  
        @Select(AuthenticationState.id) id$: Observable<number>;
        id:number;

        @Select(AuthenticationState.authorization) role$: Observable<number>;
        role:string;

        @Select(LoanTypeState.loanTypes) loanTypes$:Observable<LoanType[]>;
        loanTypes: LoanType[];

        @Select(UserState.user) user$: Observable<User>;
        user:User;


        pageLoaded: boolean;

        calculate:boolean=false;
        loanType:any;
        installment:number;
        totalAmount:number;
    

        constructor(private store: Store, private actions: Actions, private formBuilder:FormBuilder, private router:Router, private activatedRoute:ActivatedRoute) {
        this.loanTypes$.subscribe(loanTypes => this.loanTypes= loanTypes);
        this.user$.subscribe(user => this.user=user);
        this.id$.subscribe(id => this.id=id);

    }

    calculateLoan(type:any){
        this.calculate=true;
        this.loanType=type;
        this.totalAmount=null;
        this.installment=null;

    }

    calculateInstallmentAndTotal(){

        var P= this.form.value.principal;
        var I = this.form.value.interestRate/100;
        var R = (this.form.value.interestRate/100)/12;
        var y = this.form.value.numberOfYears;
        var n = this.form.value.numberOfYears * 12;
        var t= 12;

        if(this.loanType.formula=="Reducing Balance")
        this.installment=  (P * (R*(Math.pow((1+R),n)) / (Math.pow((1+R),n)-1)))

      
      

        if(this.loanType.formula=="Flat Rate")
        this.installment=(P + (P * y * (I))) / n


        this.totalAmount=this.installment*n;


    }

    checkEligible(){

        this.calculateInstallmentAndTotal()


        if(this.id)
        this.router.navigate(['/dsr'], {state: 
            {data: {
                cost:this.totalAmount, 
                installmentAmount:this.installment,
                loanAmount:this.form.value.principal,
                loanTypeId: this.loanType.loanTypeId,
            }
        }
        } 
        );
        else
        this.router.navigate(['/authentication/login']);

    }

        destroy$= new Subject<void>();

        ngOnDestroy():void {
            this.destroy$.next();
            this.destroy$.complete();
        }

        success:boolean=true;
        failure:boolean=true;

        
        loading: boolean=false;
        statusModal: boolean=false;

    ngOnInit(): void {

        this.createForm();

        this.store.dispatch([new GetAllLoanType()]).subscribe( ()  => this.pageLoaded =true);

        if(this.id)
        this.store.dispatch(new GetUser(this.id));

       
        this.actions.pipe(ofActionErrored(GetUser),takeUntil(this.destroy$)).subscribe(()=> {
            this.pageLoaded=false;
            this.store.dispatch(new Signout())
            this.store.dispatch( new GetAllLoanType());
        });

        this.actions.pipe(ofActionDispatched(Signout),takeUntil(this.destroy$)).subscribe(()=> {this.pageLoaded=false;this.store.dispatch(new GetAllLoanType())});

        

        this.actions.pipe(ofActionSuccessful(GetAllLoanType),takeUntil(this.destroy$)).subscribe(()=> {this.pageLoaded=true;});
       
        this.actions.pipe(ofActionSuccessful(CreateLoanType),takeUntil(this.destroy$)).subscribe(()=> {this.success=false;this.loading=false;this.statusModal=true;this.confirmationModal=false;});
        this.actions.pipe(ofActionErrored(CreateLoanType),takeUntil(this.destroy$)).subscribe(()=> {this.failure=false;this.loading=false;this.statusModal=true;this.confirmationModal=false;});
        this.actions.pipe(ofActionDispatched(CreateLoanType),takeUntil(this.destroy$)).subscribe(()=> {this.loading=true;this.success=true;this.failure=true;});

        this.actions.pipe(ofActionSuccessful(UpdateLoanType),takeUntil(this.destroy$)).subscribe(()=> {this.success=false;this.loading=false;this.statusModal=true;this.confirmationModal=false;});
        this.actions.pipe(ofActionErrored(UpdateLoanType),takeUntil(this.destroy$)).subscribe(()=> {this.failure=false;this.loading=false;this.statusModal=true;this.confirmationModal=false;});
        this.actions.pipe(ofActionDispatched(UpdateLoanType),takeUntil(this.destroy$)).subscribe(()=> {this.loading=true;this.success=true;this.failure=true;});
        
       
        
    }

    form: FormGroup;

    createForm(){
        this.form = this.formBuilder.group({

            principal:['',Validators.required],
            numberOfYears:['',Validators.required],
            interestRate:['', Validators.required]

        });
    }



    confirmationModal: boolean=false;
    confirmationMessage: string;
    objectId: number;
    pendingAction: string;


    confirm(action: string, objectId: number){

        this.objectId=objectId;
        this.pendingAction=action;

        
        // if(action=="create" && this.form.valid){
        // this.confirmationMessage="Are you sure you want to create this?";
        // this.confirmationModal=true;
        // }

        // if(action=="edit" && this.form.valid){
        // this.confirmationMessage="Are you sure you want to edit this?";
        // this.confirmationModal=true;
        // }

        // if(action=="delete"){
        // this.confirmationMessage="Are you sure you want to delete this?";
        // this.confirmationModal=true;
        // }

        
        }
    

    performAction(){
        // if(this.pendingAction=="create")
        // this.store.dispatch(new CreateLoanType({}));

        //  if(this.pendingAction=="edit")
        // this.store.dispatch(new UpdateLoanType({}));

        //  if(this.pendingAction=="delete")
        // this.store.dispatch(new UpdateLoanType({}));

    }




}
