import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { FormBuilder, Validators, FormGroup, FormControl } from '@angular/forms';
import { Select, Store, Actions, ofActionDispatched, ofActionSuccessful, ofActionErrored } from '@ngxs/store';
import { takeUntil } from 'rxjs/operators';
import { CreateUser } from 'src/app/store/user/user-actions';
import { CheckNumber, CheckEmail } from 'src/app/store/authentication/authentication-actions';
import { Router } from '@angular/router';
import { Role } from 'src/app/models/role';
import { GetAllRole } from 'src/app/store/role/role-actions';
import { RoleState } from 'src/app/store/role/role-state';
import * as moment from 'moment';
import { ThrowStmt } from '@angular/compiler';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit, OnDestroy {
  @Select(RoleState.roles) roles$: Observable<Role[]>;
  roles: Role[];


  destroy$= new Subject<void>();
  signUpForm: any;
  
  debouncer: any;
  userCreated: boolean;

  
  success:boolean=true;
  failure:boolean=true;

  loading: boolean=false;
  statusModal: boolean=false;
  termsModal: boolean=false;
  preview: string;

  step:number=1;
  personalDataForm: FormGroup;
  invalidUsername: boolean;
  invalidEmail: boolean;
  photoForm: FormGroup;

  constructor(private store: Store, private actions: Actions, private formBuilder: FormBuilder, private router: Router) {
    this.roles$.subscribe(role => this.roles= role);
   }

   move(step:number){
     if(step==2){
      if(this.personalDataForm.valid)
      this.step=step;
     }
     else if(step==1){
      this.step=step;
     }

    
   }

   signedUp(){
     this.router.navigate(['/home']);
   }
  
  ngOnDestroy():void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  createForm():void{


    this.signUpForm=this.formBuilder.group({
        contactNumber:['',Validators.required],
        password:['',[Validators.required, Validators.minLength(8)]],
        confirmPassword: ['', Validators.required],
        email:['',[Validators.required, Validators.email]],
      }, 
      {
        validator: [this.mustMatch('password', 'confirmPassword'), this.measureStrength('password')]
      });

      this.photoForm= this.formBuilder.group({
       
      });
  }

  onFileChange(event) {
    const reader = new FileReader();
    
    if(event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
    
      reader.onload = () => {
   
        this.preview = reader.result as string;
     
        this.signUpForm.patchValue({
         path: reader.result.toString().substring(reader.result.toString().lastIndexOf(',') + 1)
        });
   
      };
   
    }
  }

  mustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
        const control = formGroup.controls[controlName];
        const matchingControl = formGroup.controls[matchingControlName];

        if (matchingControl.errors && !matchingControl.errors.mustMatch) {
            // return if another validator has already found an error on the matchingControl
            return;
        }

        // set error on matchingControl if validation fails
        if (control.value !== matchingControl.value) {
            matchingControl.setErrors({ mustMatch: true });
        } else {
            matchingControl.setErrors(null);
        }
    }
}

validDate(controlName: string){
  return(formGroup: FormGroup) =>{
    const control=formGroup.controls[controlName];

    var date= moment(control.value,'MM/DD/YYYY',true);

    if(date.isValid()){
      control.setErrors(null)
    }else{
      control.setErrors({invalidDate :true})
    }
  
  }
}



measureStrength(controlName: string) {  


  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];

    let pass= control.value;

    if (control.errors && !control.errors.weakPassword) {
        // return if another validator has already found an error on the matchingControl
        return;
    }

    let score = 0;  

    // award every unique letter until 5 repetitions  
  
    let letters = {};  
  
    for (let i = 0; i< pass.length; i++) {  
  
    letters[pass[i]] = (letters[pass[i]] || 0) + 1;  
  
    score += 5.0 / letters[pass[i]];  
  
    }  
  
    // bonus points for mixing it up  
  
    let variations = {  
  
    digits: /\d/.test(pass),  
  
    lower: /[a-z]/.test(pass),  
  
    upper: /[A-Z]/.test(pass),  
  
    nonWords: /\W/.test(pass),  
  
    };  
  
  
  
    let variationCount = 0;  
  
    for (let check in variations) {  
  
    variationCount += (variations[check]) ? 1 : 0;  
  
    }  
  
    score += (variationCount - 1) * 10;  
  
    score= Math.trunc(score); 

    // set error on matchingControl if validation fails
    if (score < 40) {
       control.setErrors({ weakPassword: true });
    } else {
       control.setErrors(null);
    }
}
}  

signup(){
  if(this.signUpForm.valid){

    this.store.dispatch([new CheckEmail(this.signUpForm.value.email), new CheckNumber(this.signUpForm.value.contactNumber)]).subscribe(() => {
      this.store.dispatch(new CreateUser(
        {
          contactNumber: this.signUpForm.value.contactNumber,
          password:this.signUpForm.value.password,
          email:this.signUpForm.value.email,
        })
        );
    });

  }
}

terms(){
 this.termsModal=true;
}

  ngOnInit(): void {
    this.createForm();
    this.actions.pipe(ofActionDispatched(CreateUser), takeUntil(this.destroy$)).subscribe(()=>{this.loading=true;this.failure=true;this.success=true;this.statusModal=false;})
    this.actions.pipe(ofActionErrored(CreateUser), takeUntil(this.destroy$)).subscribe(()=>{this.loading=false;this.failure=false;this.statusModal=true;})
    this.actions.pipe(ofActionSuccessful(CreateUser), takeUntil(this.destroy$)).subscribe(()=>{this.loading=false;this.userCreated=true;this.success=true;this.statusModal=false;this.failure=true;})

    this.actions.pipe(ofActionErrored(CheckEmail), takeUntil(this.destroy$)).subscribe(()=>{this.invalidEmail=true;this.loading=false;})
    this.actions.pipe(ofActionErrored(CheckNumber), takeUntil(this.destroy$)).subscribe(()=>{this.invalidUsername=true;this.loading=false;})
    this.actions.pipe(ofActionSuccessful(CheckEmail), takeUntil(this.destroy$)).subscribe(()=>{this.loading=false;})
    this.actions.pipe(ofActionSuccessful(CheckNumber), takeUntil(this.destroy$)).subscribe(()=>{this.loading=false;})
    this.actions.pipe(ofActionDispatched(CheckEmail), takeUntil(this.destroy$)).subscribe(()=>{this.invalidEmail=false;this.loading=true;})
    this.actions.pipe(ofActionDispatched(CheckNumber), takeUntil(this.destroy$)).subscribe(()=>{this.invalidUsername=false;this.loading=true;})
   
   
  }

}
