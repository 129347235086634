import { State, Selector, StateContext, Action } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { LoanService } from 'src/app/services/loan.service';
import { LoanStateModel } from './loan.state-model';
import { GetAllLoan, GetLoan, UpdateLoan, CreateLoan } from './loan.actions';


@State<LoanStateModel>({
    name:'Loan'
  })
@Injectable()
  export class LoanState{
  
    constructor(private service: LoanService){
  
    }
  
    @Selector()
    static loans(state:LoanStateModel){
      return state.loans.filter( object => object.active==true);
    }

    @Selector()
    static Loan(state:LoanStateModel){
      return state.Loan;
    }
  
  
    @Action(GetAllLoan)
    getloans({patchState}:StateContext<LoanStateModel>){
      return this.service.getAll().pipe(tap(result => {
        patchState({
          loans: result
        });
      }));
    }

    @Action(GetLoan)
    getLoanMethodById({patchState}:StateContext<LoanStateModel>, {id}: GetLoan){
      return this.service.get(id).pipe(tap(result => {
        patchState({
          Loan: result
        });
      }));
    }
  
    @Action(UpdateLoan)
    updateLoanMethod({dispatch}:StateContext<LoanStateModel>,{update,id}:UpdateLoan
      ){
        return this.service.update(update, id).pipe(
          tap(
            result=>
            {
             dispatch(new GetAllLoan());
            }
          )
        )
      }

      @Action(CreateLoan)
      createLoan({dispatch, patchState}:StateContext<LoanStateModel>,{create}:CreateLoan
        ){
          return this.service.create(create).pipe(
            tap(
              result=>
              {
                dispatch(new GetAllLoan());
              }
            )
          )
        }
  
  
    
  
  }