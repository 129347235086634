<clr-main-container>
    <div class="content-container">
        <div class="content-area">
            <div class="clr-row clr-justify-content-center" style="min-height:100%;">
                <div class="clr-col-lg-4 clr-col-md-6 clr-col-sm-7 clr-align-self-center">
                    <div class="clr-col" style="text-align:center;">
                    </div>

                    
                   

                    <div class="card" style="padding:10px;">
                        <div class="card-block">
                            <div class="clr-col" style="text-align:start;">

                                <div class="clr-row clr-justify-content-center">

           
                                  
                                        <form clrForm clrLayout="vertical" [formGroup]="signUpForm" (ngSubmit)="signup()"  style="width:100%;">
                                            <div class="clr-row clr-justify-content-center">
                                                <div class="clr-col" style="width:100%;" style="text-align:center;">
              


 
                                                    <h3 style='font-weight:bolder;opacity:0.75;'>Sign Up For EZEE Loans </h3>

                                                      
                                                      <clr-input-container style="text-align:left;">
                                                        <label>Email</label>
                                                        <input clrInput type="email" formControlName="email" size="35"  />
                                                        <clr-control-error *clrIfError="'required'">This field is required!</clr-control-error>
                                                        <clr-control-error *clrIfError="'email'">This field must be a valid email address!</clr-control-error>
                                                        
                                                       
                                                        <clr-control-helper>You will receive updates and correspondence from the app from this email.</clr-control-helper>
                                                      </clr-input-container>

                                                      <p *ngIf="invalidEmail" class="p2" style="color:red;"> This Email Is Already In Use!</p>



                                                      

                                                      <clr-input-container style="text-align:left;">
                                                        <label>Contact Number</label>
                                                        <input clrInput type="text" formControlName="contactNumber" size="12" />
                                                        <clr-control-error>This is required.</clr-control-error>
                                                        <clr-control-helper>You will receive calls regarding your submissions from this number.</clr-control-helper>
                                                      </clr-input-container>

                                                      <p *ngIf="invalidUsername" class="p2" style="color:red;">This Username Is Already In Use!</p>
                                                      
                                                      
                                                      <clr-password-container style="text-align:left;">
                                                        <label>Password</label>
                                                        <input clrPassword  formControlName="password" size="30"/>
                                    
                                                        <clr-control-error *clrIfError="'required'">This field is required!</clr-control-error>
                                                        <clr-control-error *clrIfError="'minlength'">It must be at least 8 characters!</clr-control-error>
                                                        <clr-control-error *clrIfError="'weakPassword'">Password too weak!</clr-control-error>
                                                        <clr-control-helper>Passwords must contain at least 8 characters, at least one capital letter and at least one number.</clr-control-helper>
                                                      </clr-password-container>
                                                      

                                                      <app-password-strength-bar style="text-align:left;" [passwordToCheck]="signUpForm.value.password" [barLabel]="'Strength:'">  
                                                        
                                                                                        </app-password-strength-bar>  


                                                      <clr-password-container style="text-align:left;">
                                                        <label>Confirm Password</label>
                                                        <input clrPassword  formControlName="confirmPassword" size="30"/>
                                                      
                                                      <clr-control-error *clrIfError="'required'">This field is required!</clr-control-error>
                                                      <clr-control-error *clrIfError="'mustMatch'">Password must be the same!</clr-control-error>
                                                      </clr-password-container>

                                                      <br>

                                                      <div class="btn-group" >
                                                        <button type="submit" *ngIf="!loading" class="btn btn-success">Sign up</button>
                                                      </div>

                                                    

                                                     

                                                     <span class="spinner spinner-inline" *ngIf="loading">
                                            Loading...
                                        </span>
                                                    


                                                    
                                                </div>
                                            </div>
                                            
                                          </form>
                                          <clr-modal [(clrModalOpen)]="userCreated" [clrModalClosable]="false">
                                            <h3 class="modal-title">Sign Up Completed</h3>
                                            <div class="modal-body">
                                                <p>You have sucessfully created an account. Check your email for a confirmation letter from Ezze Loans and <b>use the link attached to activate your account</b> .
                                                    The email may go into your "Important" or "Spam" folder.
                                                </p>
                                            </div>
                                            <div class="modal-footer">
                                                <button type="button" class="btn btn-primary" (click)="signedUp()">
                                                   Ok
                                                </button>
                                            </div>
                                        </clr-modal>

                                        
      <clr-modal [(clrModalOpen)]="(!success || !failure) && this.statusModal"  [clrModalClosable]="false">
        <h3 class="modal-title">Action Status</h3>
        <div class="modal-body">
            <div class="clr-row">
                <div class="clr-col">
                        <clr-alert [clrAlertType]="'success'" [clrAlertClosable]="false" [(clrAlertClosed)]='success'>
                                <clr-alert-item>
                                    <span class="alert-text">
                                       Sign up completed successfully!
                                    </span>
                                </clr-alert-item>
                            </clr-alert>
                            <clr-alert [clrAlertType]="'danger'" [(clrAlertClosed)]='failure'>
                                    <clr-alert-item>
                                        <span class="alert-text">
                                            An error occured! Check internet connection.
                                        </span>
                                    </clr-alert-item>
                                </clr-alert>
                </div>
            </div>                
        </div>
        <div class="modal-footer">
          <button class="btn btn-primary" (click)="statusModal=false"> Ok</button>
        </div>
        </clr-modal>

       
                                          
                                </div>

                            </div>
                            </div>
                        </div>
                    </div>  
            </div>
        </div>
    </div>
</clr-main-container>