import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, ofActionSuccessful, Store } from '@ngxs/store';
import { takeUntil } from 'rxjs/operators';
import { Signout } from './store/authentication/authentication-actions';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent  {
  title = 'ezee-loans';


}
