import { AuthenticationState } from "./authentication/authentication-state";
import { LoanState } from "./loan/state/loan.state";
import { LoanTypeState } from "./loantype/state/loantype.state";
import { RoleState } from "./role/role-state";
import { UserState } from "./user/user-state";

export const appState=[
    AuthenticationState,
    UserState,
    LoanState,
    LoanTypeState,
    RoleState
]