export class GetLoanType{
    constructor(public id: number){
    
    }
    
    static readonly type="[LoanType] GetLoanType";
    
    }
    
    export class GetAllLoanType{
        constructor(){
        
        }
        
        static readonly type="[LoanType] GetAllLoanType";
        
        }
    
    export class UpdateLoanType{
        constructor(public update: any, public id: number){
    
        }
    
        static readonly type="[LoanType] UpdateLoanType";
    }
        
    export class CreateLoanType{
        constructor(public create:any){
    
        }
    
        static readonly type="[LoanType] CreateLoanType";
    }


