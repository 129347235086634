<clr-main-container>
    <div class="content-container">
        <div class="content-area">
            <div class="clr-row clr-justify-content-center" style="min-height:100%;">
                <div class="clr-col-lg-4 clr-col-md-6 clr-col-sm-7 clr-align-self-center">
                    <div class="clr-col" style="text-align:center;">
                    </div>

                    
                   

                    <div class="card" style="padding:10px;">
                        <div class="card-block">
                            <div class="clr-col" style="text-align:start;">

                                <div class="clr-row clr-justify-content-center">


                                    <form [hidden]="step!=1" clrForm clrLayout="vertical" [formGroup]="personalDataForm" (ngSubmit)="move(2)" style="width:100%;">
                                        <div class="clr-row clr-justify-content-center">
                                            <div class="clr-col" style="width:100%;" style="text-align:center;">
                                                <h3 style='font-weight:bolder;opacity:0.75;'>Check Your Loan Eligibility</h3>

                                    
                                                
                                                    <clr-input-container style="text-align:left;">
                                                        <label class="required">First Name</label>
                                                        <input clrInput type="text" formControlName="firstName" size="35" />
                                                        <clr-control-error *clrIfError="'required'">This field is required!</clr-control-error>
                                                      </clr-input-container>
                                                      
                                                      <clr-input-container style="text-align:left;">
                                                        <label>Middle Name</label>
                                                        <input clrInput type="text" formControlName="middleName" size="35"  />
                                                        <clr-control-error *clrIfError="'required'">This field is required!</clr-control-error>
                                                        
                                                      </clr-input-container>
                                                      
                                                      <clr-input-container style="text-align:left;">
                                                        <label>Last Name</label>
                                                        <input clrInput type="text" formControlName="lastName" size="35"  required/>
                                                        <clr-control-error *clrIfError="'required'">This field is required!</clr-control-error>
                                                      
                                                      </clr-input-container>

                                                      <clr-input-container style="text-align:left;">
                                                        <label>Date of Birth</label>
                                                       
                                                        <input clrInput type="date"  formControlName="dob" required/>
                                                        <clr-control-error *clrIfError="'required'">This field is required!</clr-control-error>
                                              
                                                      </clr-input-container>
                                                                   
                                                    
                                                      <br>

                                                      <button type="submit" *ngIf="!loading"  class="btn btn-primary">Next</button>
                                                      

                                              
                                            </div>
                                        </div>
                                    </form>

                                    <form clrForm clrLayout="vertical" [formGroup]="contactInfoForm" [hidden]="step!=2" (ngSubmit)="move(3)"  style="width:100%;">
                                      <div class="clr-row clr-justify-content-center">
                                        <div class="clr-col" style="width:100%;" style="text-align:center;">
                                            <h3 style='font-weight:bolder;opacity:0.75;'>Check Your Loan Eligibility</h3>

                                            <clr-input-container style="text-align:left;">
                                              <label>Email</label>
                                              <input clrInput type="text" formControlName="email" size="12"/>
                                              <clr-control-error *clrIfError="'required'">This field is required!</clr-control-error>
                                              <clr-control-helper>Please ensure this email is correct. We may use it to contact you.</clr-control-helper>
                                            </clr-input-container>
      
                                      <clr-input-container style="text-align:left;">

                                        <label>Contact Number</label>
                                        <input clrInput type="text" formControlName="contactNumber" size="12"  required/>
                                        <clr-control-error *clrIfError="'required'">This field is required!</clr-control-error>
                                        <clr-control-helper>Please ensure this contact number is correct. We may use it to contact you.</clr-control-helper>
                              
                                      </clr-input-container>

                                      <clr-input-container style="text-align:left;">
                                        <label>Other Number</label>
                                        <input clrInput type="text" formControlName="otherNumber" size="12"/>
                                        <clr-control-error *clrIfError="'required'">This field is required!</clr-control-error>
                              
                                      </clr-input-container>


                                      <clr-input-container style="text-align:left;">
                                        <label>Address</label>
                                        <input clrInput type="text" formControlName="address" size="35"  required/>
                                        <clr-control-error *clrIfError="'required'">This field is required!</clr-control-error>
                        
                              
                                      </clr-input-container>

                                      <br>
                                      <div class="btn-group">
                                        <button type="button" *ngIf="!loading" (click)="move(1)" class="btn btn-primary">Back</button>
                                        <button type="submit" *ngIf="!loading" class="btn btn-primary">Next</button>
                                      </div>

                                        </div>
                                      </div>

                                    </form>
    
                                  
                                        <form clrForm clrLayout="vertical" [formGroup]="financeForm" [hidden]="step!=3" (ngSubmit)="confirm('create',0)"  style="width:100%;">
                                            <div class="clr-row clr-justify-content-center">
                                                <div class="clr-col" style="width:100%;" style="text-align:center;">
                                                   


                                                    <h3 style='font-weight:bolder;opacity:0.75;'>Check Your Loan Eligibility </h3>

                                                       

                                                        <clr-input-container style="text-align:left;">
                                                            <label>Years Employed</label>
                                                            <input clrInput type="number" formControlName="employmentYears" required/>
                                                            <clr-control-error *clrIfError="'required'">This field is required!</clr-control-error>
                                                          
                                                  
                                                          </clr-input-container>

                                                          <clr-input-container style="text-align:left;">
                                                            <label>Gross Monthly Salary</label>
                                                            <input clrInput type="number" formControlName="gms" required/>
                                                            <clr-control-error *clrIfError="'required'">This field is required!</clr-control-error>
                                                          
                                                  
                                                          </clr-input-container>



                                                          <clr-input-container style="text-align:left;">
                                                            <label>Gross Monthly Expenses</label>
                                                            <input clrInput type="number" formControlName="gml" required/>
                                                            <clr-control-error *clrIfError="'required'">This field is required!</clr-control-error>
                                                    
                                                          </clr-input-container>


                                                          <clr-input-container>

                                                            <label>Gross Debt Service Ratio</label>
                                                            <input clrInput type="number" formControlName="dsr" [readonly]="true" value="{{dsr}}"/>
                                                            <clr-control-error *clrIfError="'required'">This field is required!</clr-control-error>

                                                          </clr-input-container>

                                                          <clr-input-container>
                                                            <label>Files</label>
                                                            <input
                                                            clrInput
                                                            type="file"
                                                            (change)="onFileChange($event)"
                                                          />
                                                         
                                                            <clr-control-helper>Any files that are needed to provide extra information can be attached here. This is optional.</clr-control-helper>
                                                          </clr-input-container>
                                                     


                                                      <br>

                                                      <div class="clr-row">
                                                        <div class="clr-col">
                                                          <button type="button" *ngIf="!loading" (click)="move(2)" class="btn btn-primary">Back</button>
                                                        </div>
                                                        <div class="clr-col">
                                                          <button type="button" *ngIf="!loading" class="btn btn-success" [disabled]="!financeForm.valid" (click)="calculateDSR()">Calculate GDSR</button>
                                                        </div>
                                                        <div class="clr-col">
                                                          <button type="submit" *ngIf="!loading" class="btn btn-success" [disabled]="!financeForm.valid">Contact Bank</button>
                                                        </div>
                                                       
                                                       
                                                  
                                                      </div>

                                                    

                                                     

                                                     <span class="spinner spinner-inline" *ngIf="loading">
                                            Loading...
                                        </span>
                                                    


                                                    
                                                </div>
                                            </div>
                                            
                                          </form>


                                          <clr-modal [(clrModalOpen)]="confirmationModal" [clrModalClosable]="false" [clrModalSize]="'md'">
                                            <h3 class="modal-title">Confirmation</h3>
                                            <div class="modal-body">
                                                {{confirmationMessage}}
                                            </div>
                                            
                                            <div class="modal-footer">
                                                <button class="btn btn-success" (click)="performAction()" *ngIf="!loading">Yes</button>
                                                <button class="btn btn-danger" (click)="confirmationModal=false" *ngIf="!loading">Cancel</button>
                                            
                                                <div *ngIf="loading">
                                                  <span class="spinner spinner-inline">
                                                    Loading...
                                                </span>
                                                <span>
                                                    Loading...
                                                </span>
                                                </div>
                                            
                                            
                                            </div>
                                            </clr-modal>
                                            
      <clr-modal [(clrModalOpen)]="(!success || !failure) && this.statusModal"  [clrModalClosable]="false">
        <h3 class="modal-title">Action Status</h3>
        <div class="modal-body">
            <div class="clr-row">
                <div class="clr-col">
                        <clr-alert [clrAlertType]="'success'" [clrAlertClosable]="false" [(clrAlertClosed)]='success'>
                                <clr-alert-item>
                                    <span class="alert-text">
                                       A loan officer will contact you within 24 hours.
                                    </span>
                                </clr-alert-item>
                            </clr-alert>
                            <clr-alert [clrAlertType]="'danger'" [(clrAlertClosed)]='failure'>
                                    <clr-alert-item>
                                        <span class="alert-text">
                                            An error occured! Check internet connection.
                                        </span>
                                    </clr-alert-item>
                                </clr-alert>
                </div>
            </div>                
        </div>
        <div class="modal-footer">
          <button class="btn btn-primary" (click)="goToHome()">Return Home</button>
          <button class="btn btn-danger" (click)="statusModal=false">Close</button>
          
        </div>
        </clr-modal>
                                          
                                </div>

                            </div>
                            </div>
                        </div>
                    </div>  
            </div>
        </div>
    </div>
</clr-main-container>