<clr-main-container>
    <div class="content-container">
        <div class="content-area">
            <div class="clr-row clr-justify-content-center" style="min-height:100%;">
                <div class="clr-col-lg-4 clr-col-md-6 clr-col-sm-7 clr-align-self-center">
                    <div class="clr-col" style="text-align:center;">
                        
                    </div>
                   

                    <div class="card">
                        <div class="card-block">
                            <div class="clr-col" style="text-align:center;">

                                <div class="clr-row clr-justify-content-center">
          
                   
                                     <h2 style="font-weight: bold;">Account Verified</h2>
                                     <p><b>You have successfully verified your account</b> , you can begin to use the platform.</p> 
                                </div>

                            </div>
                            </div>
                        </div>
                    </div>  
            </div>
        </div>
    </div>
</clr-main-container>
