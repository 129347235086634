

<clr-modal [(clrModalOpen)]="!success || !failure">
    <h3 class="modal-title">Action Status</h3>
    <div class="modal-body">
        <div class="clr-row">
            <div class="clr-col">
                    <clr-alert [clrAlertType]="'success'" [clrAlertClosable]="false" [(clrAlertClosed)]='success' >
                            <clr-alert-item>
                                <span class="alert-text">
                                    Password changed successfully!
                                </span>
                            </clr-alert-item>
                        </clr-alert>
                        <clr-alert [clrAlertType]="'danger'" [(clrAlertClosed)]='failure'>
                                <clr-alert-item>
                                    <span class="alert-text">
                                        An error occured! Check internet connection or code.
                                    </span>
                                </clr-alert-item>
                            </clr-alert>
            </div>
        </div>                
    </div>
    <div class="modal-footer">
        <button class="btn btn-primary" type="button" *ngIf="!success" (click)="login()">Login</button>
    </div>
    </clr-modal>

    <clr-main-container>
        
        <div class="content-container">
            <div class="content-area">
                
                <div class="clr-row clr-justify-content-center" style="min-height:100%;">
                    <div class="clr-col-lg-4 clr-col-md-6 clr-col-sm-7 clr-align-self-center">
                        <div class="clr-col" style="text-align:center;">
                        </div>
                       
    
                        <div class="card">
                            <div class="card-block">
                                <div class="clr-col" style="text-align:start;width:100%;">

                                    <div class="clr-row clr-justify-content-center">

                                        
                                        <form clrForm [formGroup]="recoverForm" (ngSubmit)="sendEmail()" *ngIf="step==1">

                                            <div class="clr-row clr-justify-content-center">
                                                <div class="clr-col" style="width:100%;" style="text-align:center;">
                                                    
                                                    <h2 style="font-weight:500;color:rgb(53, 51, 55);margin:0;padding:0;">Recover Your Account</h2>

                                                    <p *ngIf="step==1">We can help you reset your security information. First, enter your email below and press 'Next' below.</p>

                                                    <clr-alert *ngIf="invalidEmail" [clrAlertSizeSmall]="true" [clrAlertType]="'danger'" (clrAlertClosedChange)="invalidEmail=false">
                                                        <clr-alert-item>
                                                            <span class="alert-text">
                                                                Invalid email.
                                                            </span>
                                                        </clr-alert-item>
                                                    </clr-alert>

                                   

                                                    <clr-input-container style="text-align:left;">
                                                        <input clrInput type="text" placeholder="Email" formControlName="email" size="40" />
                                                        <clr-control-error *clrIfError="'required'">This field is required!</clr-control-error>
                                                      </clr-input-container>


                                                      <br>

                                                      <button class="btn btn-primary recover-button" *ngIf="!loading" type="submit" >Next</button>
                                                      <span class="spinner spinner-inline" *ngIf="loading">
                                                        Loading...
                                                    </span>

                                                </div>
                                            </div>

                                            
                                        
        
                                           
        
                                        </form>

                                        
                                        <form clrForm [formGroup]="codeForm" *ngIf="step==2" (ngSubmit)="changePassword()" >

                                            <div class="clr-row clr-justify-content-center">
                                                <div class="clr-col" style="width:100%;" style="text-align:center;">

                                                     <h2 style="font-weight:500;color:rgb(53, 51, 55);margin:0;padding:0;">Recover Your Account</h2>

                                                    <p *ngIf="step==2">Enter the code that was sent to your email and your new password. <a>Click here</a> to resend the email.</p>

                                                    <clr-input-container style="text-align:left;">
                                                        <input clrInput type="number" formControlName="code" placeholder="Code" min="0" max="9999" class="recover-field"/>
                                                        <clr-control-error *clrIfError="'required'">This field is required!</clr-control-error>
                                                      </clr-input-container>


                                                      

                                                     



                                                      <clr-input-container style="text-align:left;">
                                                        <input clrInput type="text" formControlName="password" placeholder="New Password" size="40" class="recover-field"/>
                                                        <clr-control-error *clrIfError="'required'">This field is required!</clr-control-error>
                                                      
                                                        <clr-control-error *clrIfError="'minlength'">It must be at least 8 characters!</clr-control-error>
                                                        <clr-control-error *clrIfError="'weakPassword'">Password too weak!</clr-control-error>
                                                        <clr-control-helper>Passwords must contain at least 8 characters, one capital letter and at least one number.</clr-control-helper>
                                                      </clr-input-container>

                                                      <app-password-strength-bar style="text-align:center;" [passwordToCheck]="codeForm.value.password" [barLabel]="'Strength:'">  
                                                        
                                                    </app-password-strength-bar>  

                                                      <clr-input-container style="text-align:left;">
                                                        <input clrInput type="text" formControlName="confirmPassword" size="40" placeholder="Confirm New Password" class="recover-field"/>
                                                        <clr-control-error *clrIfError="'required'">This field is required!</clr-control-error>
                                                        <clr-control-error *clrIfError="'mustMatch'">Password must be the same!</clr-control-error>
                                                      </clr-input-container>

                                                   
        
                                           
                                            

                                            <br>
        
                                            
                                            <button class="btn btn-primary recover-button" *ngIf="!loading" type="submit">Submit</button>
                                            <span class="spinner spinner-inline" *ngIf="loading">
                                                Loading...
                                            </span>
        
                                                </div>
                                            </div>    
                                            
                                        </form>

                                        

                                    </div>
    
                                        
        
                                     
        
                                      
        
                                        
                                </div>
                                </div>
                            </div>
                        </div>  
                </div>
            </div>
        </div>
    </clr-main-container>
    