<clr-header style="background-color:#2e5f35;">
        <div class="branding" >
           
                <img src="../../../assets/logo_white.png" alt="">

        </div>

        
        <div class="header-actions">
            <clr-dropdown>
            <button class="nav-text" clrDropdownTrigger aria-label="open user profile">
                 <span *ngIf="role$ | async">Hello User</span> 
                 <span *ngIf="!(role$ | async)">You are not logged in!</span>

                <clr-icon shape="caret down" class="is-solid"></clr-icon>
            </button>
            <clr-dropdown-menu *clrIfOpen clrPosition="bottom-right">
                <button clrDropdownItem *ngIf="!(role$ | async)" (click)="login()">
                    Login
                </button>
                <button clrDropdownItem *ngIf="!(role$ | async)" (click)="signup()">
                    Signup
                </button>

                <button clrDropdownItem *ngIf="(role$ | async)" (click)="logout()">
                    Logout
                </button>
            </clr-dropdown-menu>
        </clr-dropdown>
        </div>
</clr-header>

  