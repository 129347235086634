export class GetLoan{
    constructor(public id: number){
    
    }
    
    static readonly type="[Loan] GetLoan";
    
    }
    
    export class GetAllLoan{
        constructor(){
        
        }
        
        static readonly type="[Loan] GetAllLoan";
        
        }
    
    export class UpdateLoan{
        constructor(public update: any, public id: number){
    
        }
    
        static readonly type="[Loan] UpdateLoan";
    }
        
    export class CreateLoan{
        constructor(public create:any){
    
        }
    
        static readonly type="[Loan] CreateLoan";
    }


