import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store, Select, Actions, ofActionSuccessful } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { AuthenticationState } from 'src/app/store/authentication/authentication-state';
import { Signout } from 'src/app/store/authentication/authentication-actions';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

  @Select(AuthenticationState.id) id$: Observable<number>;
  id:number;
  @Select(AuthenticationState.authorization) role$: Observable<string>;
  role:string;



  destroy$= new Subject<void>();

  token: string;

  



  ngOnDestroy():void {
    this.destroy$.next();
    this.destroy$.complete();
  }
  @Select(AuthenticationState.token) token$: Observable<string>;


   

  constructor(private router: Router, private store: Store, private actions:Actions) {
    this.role$.subscribe(role => this.role =role);
    this.id$.subscribe(id => this.id=id);
    this.token$.subscribe(token => this.token = token);
   }


   

  logout(){
    this.store.dispatch(new Signout);
    localStorage.removeItem('authentication');
  }

  ngOnInit() {

    this.actions.pipe(ofActionSuccessful(Signout),takeUntil(this.destroy$)).subscribe(()=> this.router.navigate(['/home']));



    
   }

   login(){
     this.router.navigate(['/authentication/login'])
   }

   signup(){
    this.router.navigate(['/authentication/sign-up'])
  }



}
