import { State, Selector, StateContext, Action } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { LoanTypeService } from 'src/app/services/loan-type.service';
import { LoanTypeStateModel } from './loantype.state-model';
import { GetAllLoanType, GetLoanType, UpdateLoanType, CreateLoanType } from './loantype.actions';


@State<LoanTypeStateModel>({
    name:'LoanType'
  })
@Injectable()
  export class LoanTypeState{
  
    constructor(private service: LoanTypeService){
  
    }
  
    @Selector()
    static loanTypes(state:LoanTypeStateModel){
      return state.loanTypes.filter( object => object.active==true);
    }

    @Selector()
    static LoanType(state:LoanTypeStateModel){
      return state.LoanType;
    }
  
  
    @Action(GetAllLoanType)
    getloanTypes({patchState}:StateContext<LoanTypeStateModel>){
      return this.service.getAll().pipe(tap(result => {
        patchState({
          loanTypes: result
        });
      }));
    }

    @Action(GetLoanType)
    getLoanTypeMethodById({patchState}:StateContext<LoanTypeStateModel>, {id}: GetLoanType){
      return this.service.get(id).pipe(tap(result => {
        patchState({
          LoanType: result
        });
      }));
    }
  
    @Action(UpdateLoanType)
    updateLoanTypeMethod({dispatch}:StateContext<LoanTypeStateModel>,{update,id}:UpdateLoanType
      ){
        return this.service.update(update, id).pipe(
          tap(
            result=>
            {
             dispatch(new GetAllLoanType());
            }
          )
        )
      }

      @Action(CreateLoanType)
      createLoanType({dispatch, patchState}:StateContext<LoanTypeStateModel>,{create}:CreateLoanType
        ){
          return this.service.create(create).pipe(
            tap(
              result=>
              {
                dispatch(new GetAllLoanType());
              }
            )
          )
        }
  
  
    
  
  }