import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component';
import { SignupComponent } from './signup/signup.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { AuthenticationComponent } from './authentication/authentication.component';
import { SharedModule } from '../shared/shared.module';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { ClarityModule } from '@clr/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthenticationRoutingModule } from './authentication-routing.module';
import { ResendVerifyComponent } from './resend-verify/resend-verify.component';
import { VerifiedComponent } from './verified/verified.component';
import { ImageCropperModule } from 'ngx-image-cropper';



@NgModule({
  declarations: [LoginComponent, SignupComponent, ForgotPasswordComponent, AuthenticationComponent, ResendVerifyComponent, VerifiedComponent],
  imports: [
    CommonModule,
    ClarityModule,
    ImageCropperModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    RouterModule,
    SharedModule
  ],
  exports:[
    LoginComponent, SignupComponent, ForgotPasswordComponent, AuthenticationComponent,AuthenticationRoutingModule
  ]
})
export class AuthenticationModule { }
